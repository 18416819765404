:root {
  --primary-color: #006f8f;
  --secondary-color: #f25a23;
  --tertiary-color: #383c46;
  --dark-grey-1: #747989;
  --dark-grey-2: #acafba;
  --light-grey-1: #cccfd0;
  --light-grey-2: #eaf1f6;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}
html {
  font-size: 16px;
}
body {
  overflow-x: hidden;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  color: var(--tertiary-color);
  min-height: 110%;
  height: 100%;
}
body::-webkit-scrollbar {
  display: none;
}

.quotes__error {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quotes__error img {
  width: 20%;
  height: auto;
}
.quotes__error span {
  width: 73%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #32ba7c;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin-bottom: 28px;
  box-shadow: inset 0px 0px 0px #32ba7c;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-width: 4;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #32ba7c;
  }
}
